const breads = {
  name: "Sub Bread",
  modType: "chooseOne",
  options: [
    { label: "NONE", value: "BX36BIQTE5SZFE4EYIZL6ODE_0" },
    { label: "Croissant ($1.00)", value: "B5N2OZPBJTTLJ7WI42S2MYLX_1" },
    { label: "Brioche ($0.50)", value: "3NZJXA4J45KZ2G3RATRJ3JGT_.5" },
    { label: "Baguette ($0.50)", value: "MIY65C4DK2EKFSBZQ7GIKCA5_.5" },
    { label: "Multigrain ($0.50)", value: "WEFDZSRCPIFN343DCM36D3G7_.5" },
    { label: "Levain ($0.50)", value: "D5VS4GLMNR5HPXUCCXJGT7SX_.5" },
  ],
};
const queenOfClubs = {
  name: "Queen of Clubs",
  modType: "chooseMany",
  options: [
    { label: "NO Turkey", value: "NO QS3R2TS3WHNOZSSETE3ZEWIA_0" },
    { label: "NO Bacon", value: "OHXILYVTW7TDZL3HNDYCROE3_0" },
    { label: "NO Cheese", value: "E42T62BEGHS5PFH3IIKHERDK_0" },
    { label: "NO Cucumber", value: "D52TV5LEP25I3FUIXXAEQCMQ_0" },
    { label: "NO Tomato", value: "VPC2W5GV25IAPC5FLJKAWBIV_0" },
    { label: "NO Lettuce", value: "7S2NRSLIEW46K3ATV7PGH3JK_0" },
    { label: "NO Aioli", value: "7QJZBB6LTGKEFD3BQD5N3T2M_0" },
  ],
};
const avocado = {
  name: "Avocado",
  modType: "chooseMany",
  options: [
    { label: "ADD Avocado ($2.00)", value: "X5WTWZ5UGQPDZGF5XK4GD67M_2" },
  ],
};
const chips = {
  name: "Chips",
  modType: "chooseMany",
  options: [
    { label: "ADD Chips ($2.00)", value: "QY6RCULQI2H6NI7H6UMLZJL4_2" },
  ],
};
const theWorks = {
  name: "The Works",
  modType: "chooseMany",
  options: [
    { label: "ADD Peperoncini", value: "YVRHZ4UZY625D2RBYZYBQBGN_0" },
    { label: "NO Oil", value: "CWCWJEORV7JTXRFRO2REAXDA_0" },
    { label: "NO Vinegar", value: "BCG3HECOWDUYJZJBCNDTDZMO_0" },
    { label: "NO Salt", value: "BE5PTX3J2RX3FJINDZV6TAGF_0" },
    { label: "NO Pepper", value: "JARNUDV53KC7JO2RMIK6BBR3_0" },
    { label: "NO Mayo", value: "CTZIXCYHUEALSO2L5QKJ3U3U_0" },
    { label: "NO Mustard", value: "VI2CXZVRRRJFB73OHILPPX2I_0" },
    { label: "NO Pickles", value: "2QRQ2ZF75VXBG33423QQLRG2_0" },
  ],
};
const unDutchAble = {
  name: "UnDutchable",
  modType: "chooseMany",
  options: [
    { label: "NO Salami", value: "HK3ITNXA4DH3SNELK5BDGBAD_0" },
    { label: "NO Ham", value: "44GMBPJ3MJBCATL2Y55H4KVB_0" },
    { label: "NO Capocollo", value: "P2AVBLDAHJ4NAHD4N5GRYWGN_0" },
    { label: "NO Provolone", value: "2PYKSLMHBB73TXNJ5SYOSIOA_0" },
    { label: "NO Onion", value: "GGXIXJWAK7TSH26DRA5IESWR_0" },
    { label: "NO Tomato", value: "ARPJSFC3HEBQQ75TVOROF5PJ_0" },
    { label: "NO Lettuce", value: "EVTFFGKVY2OTCM6Z3OXPA4AX_0" },
  ],
};
const californiaGarden = {
  name: "The California Garden",
  modType: "chooseMany",
  options: [
    { label: "NO Avocado", value: "NGQNZVF5HVKGMZJK7XNMJU6DF_0" },
    { label: "NO Lettuce", value: "ZDXXJFYYAC7DSYZ62XSO5JB5_0" },
    { label: "NO Pickled Veggies", value: "UGF5OTPXQOCP25G6M4UNS6L2_0" },
    { label: "NO Cucumber", value: "FUOXGCKVPUMUNO5GMQCUDGVO_0" },
    { label: "NO Cheese", value: "HDKTW5EJCVGXHHK2NQ7MK7R5_0" },
    { label: "Sub Cream Cheese", value: "SVHZDVL56EIW4U3DMBR6CESZ_0" },
    { label: "NO Dressing", value: "MLWQ22E3MAAZAT6MGFMC3OHA_0" },
  ],
};
const backPorchBLT = {
  name: "Sweet n Spicey BLT",
  modType: "chooseMany",
  options: [
    { label: "NO Lettuce", value: "IYXEWOZST2HM4O6BX4DU5XZ7_0" },
    { label: "NO Tomato", value: "PKI4PEKXICSI43MFHW5AIMZS_0" },
    { label: "NO Sambal Aioli", value: "HHCL4RGTDLROXSRY5N5D63KG_0" },
    { label: "Sub Regular Aioli", value: "BDSIFOISRYMISIC3ZJJOVL4A_0" },
    { label: "Sub NOT Spicey Bacon", value: "L2WQWHGVWQLBLZUOSC2ENCL3_0" },
  ],
};
const countryTurkey = {
  name: "Country Turkey",
  modType: "chooseMany",
  options: [
    { label: "ADD Bacon ($2.00)", value: "TBAPLWYK3M2DI6H6FIKIPLJU_2" },
    { label: "NO Cheese", value: "UMHPURUOVSDQYF3DCKYLKCK6_0" },
    { label: "NO Onion", value: "PVSFZ3R7VCJ7GEMJRR2QUFVB_0" },
    { label: "NO Pickles", value: "COYZB5MKCKERRBPOAD6NCYAL_0" },
    { label: "NO Lettuce", value: "S3O2ZEBW3MPK6M7362O6AIMW_0" },
    { label: "NO Aioli", value: "NYWD3HOCTBCHPW3LAP5536F7_0" },
  ],
};
export const menu = [
  {
    title: "Thanksgiving",
    info: "Order now!!",
    items: [
      {
        name: "Pumpkin Tart (7 in)",
        description:
          "Azita's 7 inch Pumpkin Tart w/ cinnamon and pumpkin ganache.",
        price: 27,
        sqid: "TKFTYXRTLAGK3KAUXPJ5YNSE",
        variations: [
          {
            varid: "L5ZMBJZQPBZMY3CUIF4NHFAM",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
      
        url: "https://items-images-production-f.squarecdn.com/files/01c0f968be980e827611de1e4a7ce75dd1cefe79/original.jpeg?width=516&fit=bounds&enable=upscale",
        location: "carlton",
        start: 7,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },

      
      {
        name: "Chocolate Caramel Pecan Pie (7 in)",
        description: "Azita's Chocolate Caramel Pecan Pie (7 in)",
        price: 27,
        sqid: "7BYMXB5KRH5UMYR6X5ITHCP5",
        variations: [
          {
            varid: "HKJN5XINIWXJDI6TMABQP4YZ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p458_i1_w2791.jpeg",
        location: "carlton",
        start: 7,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      {
        name: "Pumpkin Pie",
        description: "Classic Pumpkin Pie",
        price: 23,
        sqid: "NLCMLDOGFWIZJZQLUOOBVYEI",
        variations: [
          {
            varid: "NVLOBXO4DIXDQV4FBGE36NTD",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p463_i1_w800.jpeg",
        location: "carlton",
        start: 7,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      {
        name: "Pecan Pie",
        description: "Classic Pecan Pie",
        price: 25,
        sqid: "TONUVVEZNERSIUHOZ2T6Y7KG",
        variations: [
          {
            varid: "P7AD2E4C55U7DJ76UL6Z6UZJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p467_i1_w800.jpeg",
        location: "carlton",
        start: 7,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      {
        name: "Brioche Dinner Rolls (12)",
        description:
          "Wheat flour, water, butter, sugar, milk powder, salt, yeast",
        price: 7.35,
        sqid: "JT33KV4Y6E2ALGTJ2BYMCXMN",
        variations: [
          {
            varid: "SDUDPDWHCOBU2GZR5SHGFDNI",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p260_i1_w590.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Pesto Pull Apart Brioche Loaf",
        description: "Pesto Pull Apart Brioche Loaf",
        price: 9.75,
        sqid: "IHXEVJV375MWQDJ6O32KWVLN",
        variations: [
          {
            varid: "MM2KMHYOVSXMM45U3KEZASDJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p469_i1_w2484.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      
      {
        name: "Pumpkin Tart (7 in)",
        description:
          "Azita's 7 inch Pumpkin Tart w/ cinnamon and pumpkin ganache.",
        price: 27,
        sqid: "TKFTYXRTLAGK3KAUXPJ5YNSE",
        variations: [
          {
            varid: "L5ZMBJZQPBZMY3CUIF4NHFAM",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://items-images-production-f.squarecdn.com/files/01c0f968be980e827611de1e4a7ce75dd1cefe79/original.jpeg?width=516&fit=bounds&enable=upscale",
        location: "prado",
        start: 8.5,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      

      {
        name: "Chocolate Caramel Pecan Pie (7 in)",
        description: "Azita's Chocolate Caramel Pecan Pie (7 in)",
        price: 27,
        sqid: "7BYMXB5KRH5UMYR6X5ITHCP5",
        variations: [
          {
            varid: "HKJN5XINIWXJDI6TMABQP4YZ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p458_i1_w2791.jpeg",
        location: "prado",
        start: 8.5,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },

      {
        name: "Pumpkin Pie",
        description: "Classic Pumpkin Pie",
        price: 23,
        sqid: "NLCMLDOGFWIZJZQLUOOBVYEI",
        variations: [
          {
            varid: "NVLOBXO4DIXDQV4FBGE36NTD",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p463_i1_w800.jpeg",
        location: "prado",
        start: 8.5,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },

      {
        name: "Pecan Pie",
        description: "Classic Pecan Pie",
        price: 25,
        sqid: "TONUVVEZNERSIUHOZ2T6Y7KG",
        variations: [
          {
            varid: "P7AD2E4C55U7DJ76UL6Z6UZJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p467_i1_w800.jpeg",
        location: "prado",
        start: 8.5,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      {
        name: "Brioche Dinner Rolls (12)",
        description:
          "Wheat flour, water, butter, sugar, milk powder, salt, yeast",
        price: 7.35,
        sqid: "JT33KV4Y6E2ALGTJ2BYMCXMN",
        variations: [
          {
            varid: "SDUDPDWHCOBU2GZR5SHGFDNI",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p260_i1_w590.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Pesto Pull Apart Brioche Loaf",
        description: "Pesto Pull Apart Brioche Loaf",
        price: 9.75,
        sqid: "IHXEVJV375MWQDJ6O32KWVLN",
        variations: [
          {
            varid: "MM2KMHYOVSXMM45U3KEZASDJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p469_i1_w2484.jpeg",
        location: "prado",
        start: 8.5,
        lead: 2,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },

      
    ],
  },
  {
    title: "Breakfast Sandwiches",
    info: "Breakfast sandwiches are available all day.",
    items: [
      {
        name: "Turkey, Egg, and Avocado Croissant",
        description: "Egg, Turkey, Avocado, Aioli on a Back Porch Croissant",
        price: 10.80,
        sqid: "BPIFLD7D47QWOCVFOWIKHL4B",
        variations: [
          {
            varid: "AQ4APWTX5LQVSGBKPMLCLU2Y",
            varDescrip: "",
            priceDiff: 0,
          },
        ],

        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p382_i1_w2560.jpeg",
        location: "carlton",
        start: 7,
        lead: 0,
        days: null,
        specialStart: null,
        specialEnd: null,
        modifiers: [breads],
      },
      {
        name: "Back Porch Breakfast Croissant",
        description:
          "Egg, Provolone, Tomato, Lettuce, Aioli on a Back Porch Croissant",
        price: 10.80,
        sqid: "FFOF4O7LPKZB6RTPMCLEWV27",
        variations: [
          {
            varid: "4LKBJHSJMMOQSHSASUNFW3Z2",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p379_i1_w2560.jpeg",
        location: "carlton",
        start: 7,
        lead: 0,
        days: null,
        specialStart: null,
        specialEnd: null,
        modifiers: [breads],
      },
      {
        name: "Bacon, Eggs, and Cheddar Croissant",
        description: "Bacon, Egg, Cheddar and Aioli on a Back Porch Croissant",
        price: 10.80,
        sqid: "Z3DFBQXUXTFU2E3R2UEF2ZGJ",
        variations: [
          {
            varid: "2GWFUDD4QXTJPVDPRVS37BSD",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p378_i2_w2880.jpeg",
        location: "carlton",
        start: 7,
        lead: 0,
        days: null,
        specialStart: null,
        specialEnd: null,
        modifiers: [breads],
      },
    ],
  },
  {
    title: "Lunch Sandwiches",
    info: "Lunch sandwiches are available from 10 AM.",
    items: [
      {
        name: "The Un.Dutch.able",
        description:
          "Italian Deli Sub on Fresh Baked Dutch Crunch Roll. Salami, ham, capocollo, provolone, red onion, tomato, lettuce, pickle, mayo, mustard, salt, pepper, oil and vinegar. Pepperoncini on request.",
        price: 12.45,
        sqid: "BBRCDEQKKR6DKE4KYHWYUUOE",
        variations: [
          {
            varid: "36V7LW42CKVVJUBXSUI5OSBI",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p371_i1_w2560.jpeg",
        location: "carlton",
        start: 10,
        lead: 0,
        days: null,
        specialStart: null,
        specialEnd: null,
        modifiers: [breads, unDutchAble, avocado, theWorks, chips],
      },
      {
        name: "The Queen of Clubs",
        description:
          "Turkey, bacon, cheddar, cucumber, tomato, lettuce, sundried tomato aioli on Back Porch Croissant.",
        price: 12.45,
        sqid: "53K44KCSZVNECIT65FGG3W4P",
        variations: [
          {
            varid: "SP6LVRMWV5PSFQPUATN36U74",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p370_i1_w2560.jpeg",
        location: "carlton",
        start: 10,
        lead: 0,
        days: null,
        specialStart: null,
        specialEnd: null,
        modifiers: [breads, queenOfClubs, avocado, chips],
      },
      {
        name: "The California Garden",
        description:
          "Avocado, Lettuce, cucumber, pickled veggies (carrots, onion, cucumber, mild chiles), Goat cheese, with Greek dressing on Back Porch multigrain bread.",
        price: 11.35,
        sqid: "ZWJY7ZMMPRIIKWLJQYZGXKZR",
        variations: [
          {
            varid: "LVYDXMISMDVCJHJG7KGFXH46",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p369_i1_w2560.jpeg",
        location: "carlton",
        start: 10,
        lead: 0,
        days: null,
        specialStart: null,
        specialEnd: null,
        modifiers: [breads, californiaGarden, chips],
      },
      {
        name: "Sweet n Spicy BLT",
        description:
          "Sweet & Spicy applewood smoked bacon, lettuce, and tomato with sambal aioli on sliced brioche.",
        price: 11.35,
        sqid: "EENAYWJU6RFSCBKMRR4TTU66",
        variations: [
          {
            varid: "ETGPHN55ERPC6YFYP3GFU44M",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p368_i1_w2560.jpeg",
        location: "carlton",
        start: 10,
        lead: 0,
        days: null,
        specialStart: null,
        specialEnd: null,
        modifiers: [breads, backPorchBLT, avocado, chips],
      },
      {
        name: "Country Turkey",
        description:
          "Roasted turkey, provolone, pickle, red onions, lettuce, sundried tomato aioli on pan au levain.",
        price: 11.55,
        sqid: "YZZUMX42G7NP3T5CR2KLSOFF",
        variations: [
          {
            varid: "JUQMMYMXQGD6FNLCWVT26WVW",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p366_i1_w2560.jpeg",
        location: "carlton",
        start: 10,
        lead: 0,
        days: null,
        specialStart: null,
        specialEnd: null,
        modifiers: [breads, countryTurkey, avocado, chips],
      },
      {
        name: "Salami and Brie Ficelle",
        description:
          "Salami, Brie, Aioli, and mixed green and basil on Back Porch Ficelle.",
        price: 8.75,
        sqid: "A5CD5LNWGEQEBQTNXZYDBWQQ",
        variations: [
          {
            varid: "YQCQNHQPPGIOWTO52REHYFIW",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p367_i1_w2560.jpeg",
        location: "carlton",
        start: 10,
        lead: 0,
        days: null,
        specialStart: null,
        specialEnd: null,
        modifiers: [breads, chips],
      },
      {
        name: "Caprese Ficelle",
        description:
          "Pesto, Mozzarella, Tomato, Balsamic, and mixed greens and basil on a Back Porch Ficelle.",
        price: 8.75,
        sqid: "2JDDMZ547IAZOICW3FZ43CNC",
        variations: [
          {
            varid: "LTSRZ3OL2D4SNHMOVVMY6NXX",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p365_i1_w2560.jpeg",
        location: "carlton",
        start: 10,
        lead: 0,
        days: null,
        specialStart: null,
        specialEnd: null,
        modifiers: [breads, chips],
      },
    ],
  },
  {
    title: "Pastries",
    info: "Pastries baked fresh daily.",
    items: [
      {
        name: "Chocolate Croissant",
        description:
          "Wheat flour, water, butter, chocolate, sugar, salt, yeast",
        price: 4.85,
        sqid: "5WV2EBECSFPICUDUXNE6EWPQ",
        variations: [
          {
            varid: "2V2IT2GF3UVTJR2OGVKHOGDX",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p276_i1_w2431.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Morning Bun",
        description:
          "Wheat flour, water, butter, sugar, salt, cinammon, orange zest, yeast",
        price: 4.85,
        sqid: "A2KJVO3L6LEVZ3OBDGFU53PB",
        variations: [
          {
            varid: "DJ6FYVZ3RWLLZ7YXKVDXXIVX",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p277_i1_w2448.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "All Butter Croissant",
        description: "Wheat flour, water, butter, sugar, salt, yeast",
        price: 3.70,
        sqid: "WKGVREPSY7K7ZNDGRYWWWCJQ",
        variations: [
          {
            varid: "L57KSSSKPPQIFCPVIXP3Z3S4",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p278_i1_w434.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Almond Croissant",
        description:
          "Wheat flour, water, butter, eggs, almonds, sugar, salt, almond extract, yeast",
        price: 6.25,
        sqid: "SUZ6S4BR5BO7K2YDXL34R7UN",
        variations: [
          {
            varid: "4FRWGMXFYGWSGPUAXE5TT4MJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p257_i1_w2448.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Spinach Feta Croissant",
        description:
          "Wheat flour, water, butter, spinach, feta, cream cheese, parsley, dill, sugar, salt, yeast",
        price: 6.25,
        sqid: "VRGUZK6LRX5VLQJEKLP2JZC2",
        variations: [
          {
            varid: "R4FMT3Z5RUNM4RBLG7YPDH4X",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p273_i1_w420.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Ham and Cheese Croissant",
        description:
          "Wheat flour, water, butter, ham, swiss cheese, sugar, salt, yeast.",
        price: 6.25,
        sqid: "V2RSBJ7GVUDXRRGDS5MZC6T7",
        variations: [
          {
            varid: "IEAEXPWDTZWIJ35NFNK23NFO",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p259_i2_w2448.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "French Toast Muffin",
        description: "French Toast Muffin",
        price: 4.55,
        sqid: "4B5LPNE2JBSXZH3AO5H5W37T",
        variations: [
          {
            varid: "ZUULGBKNP4LEAZ2IR5U25L2E",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p372_i2_w1000.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },

      {
        name: "Chocolate Croissant",
        description:
          "Wheat flour, water, butter, chocolate, sugar, salt, yeast",
        price: 4.85,
        sqid: "5WV2EBECSFPICUDUXNE6EWPQ",
        variations: [
          {
            varid: "2V2IT2GF3UVTJR2OGVKHOGDX",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p276_i1_w2431.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Morning Bun",
        description:
          "Wheat flour, water, butter, sugar, salt, cinammon, orange zest, yeast",
        price: 4.85,
        sqid: "A2KJVO3L6LEVZ3OBDGFU53PB",
        variations: [
          {
            varid: "DJ6FYVZ3RWLLZ7YXKVDXXIVX",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p277_i1_w2448.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "All Butter Croissant",
        description: "Wheat flour, water, butter, sugar, salt, yeast",
        price: 3.70,
        sqid: "WKGVREPSY7K7ZNDGRYWWWCJQ",
        variations: [
          {
            varid: "L57KSSSKPPQIFCPVIXP3Z3S4",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p278_i1_w434.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Almond Croissant",
        description:
          "Wheat flour, water, butter, eggs, almonds, sugar, salt, almond extract, yeast",
        price: 6.25,
        sqid: "SUZ6S4BR5BO7K2YDXL34R7UN",
        variations: [
          {
            varid: "4FRWGMXFYGWSGPUAXE5TT4MJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p257_i1_w2448.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Spinach Feta Croissant",
        description:
          "Wheat flour, water, butter, spinach, feta, cream cheese, parsley, dill, sugar, salt, yeast",
        price: 6.25,
        sqid: "VRGUZK6LRX5VLQJEKLP2JZC2",
        variations: [
          {
            varid: "R4FMT3Z5RUNM4RBLG7YPDH4X",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p273_i1_w420.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Ham and Cheese Croissant",
        description:
          "Wheat flour, water, butter, ham, swiss cheese, sugar, salt, yeast.",
        price: 6.25,
        sqid: "V2RSBJ7GVUDXRRGDS5MZC6T7",
        variations: [
          {
            varid: "IEAEXPWDTZWIJ35NFNK23NFO",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p259_i2_w2448.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
    ],
  },
  {
    title: "Rustic Breads & Brioche",
    info: "Breads baked fresh daily.",
    items: [
      {
        name: "Brioche Loaf",
        description:
          "Wheat flour, water, butter, sugar, milk powder, salt, yeast",
        price: 7.75,
        sqid: "M7ZPR7KYXNN3JLQDD3ZQLZZE",
        variations: [
          {
            varid: "VLVKJUJQH7NM2M7UI3DB2L2Z",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p340_i2_w1000.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Brioche Buns (8)",
        description:
          "Wheat flour, water, butter, sugar, milk powder, salt, yeast",
        price: 7.75,
        sqid: "MGDRYUMVNXHFFOICMQRGFHJO",
        variations: [
          {
            varid: "CPJYNXTEMMF52DTYIJAWS4CB",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p258_i2_w2448.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Brioche Dinner Rolls (12)",
        description:
          "Wheat flour, water, butter, sugar, milk powder, salt, yeast",
        price: 7.75,
        sqid: "JT33KV4Y6E2ALGTJ2BYMCXMN",
        variations: [
          {
            varid: "SDUDPDWHCOBU2GZR5SHGFDNI",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p260_i1_w590.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Olive Herb",
        description:
          "Wheat flour, water, kalamata olives, queen olives, natural starter, herbs de provence, salt, yeast",
        price: 8.35,
        sqid: "OXASXKSSZWM3S4HYIZBOHYQW",
        variations: [
          {
            varid: "SZXTYVLNSU2U5PHCTY5PX7AJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p5_i2_w2448.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      
      {
        name: "Blue Cheese Walnut",
        description:
          "Wheat flour, water, walnuts, blue cheese, natural starter, salt, yeast",
        price: 8.35,
        sqid: "CVKZ6E3OCHBKDVB7YZPB3J6N",
        variations: [
          {
            varid: "77OSDARFCER2RPZJPMG42SC2",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p3_i2_w2448.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },

      {
        name: "Epi",
        description: "Wheat flour, water, natural starter, salt, yeast",
        price: 6.00,
        sqid: "QNZ6RBYHJ5JZGYE5VYLI2BMZ",
        variations: [
          {
            varid: "I77ASB3EL5ZJFVN54GDOQOMH",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p11_i2_w600.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "BPB Baguette",
        description: "Wheat flour, water, natural starter, salt, yeast",
        price: 3.7,
        sqid: "MXWDWKDPJ2633BO7SURJWOLM",
        variations: [
          {
            varid: "JGOQX233Y6IR76GRNW32A5BY",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p272_i1_w800.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Country Rye",
        description:
          "Wheat flour, rye flour, water, natural starter, salt, yeast",
        price: 7.75,
        sqid: "C34CXVX6JXTIACXRDCV5PADU",
        variations: [
          {
            varid: "7M7MZ6A6DTHVOGPJGE4KJJSD",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p279_i1_w2448.jpeg",
        location: "carlton",
        start: 7,
        lead: 3,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Country Batard",
        description: "Wheat flour, water, natural starter, salt",
        price: 7.75,
        sqid: "M72IUS7PDXZ3QA6DKSNKNRY5",
        variations: [
          {
            varid: "WBS7S2L5TNEZRJ735ZXEONJ3",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p275_i1_w2560.jpeg",
        location: "carlton",
        start: 7,
        lead: 3,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Multigrain",
        description:
          "Wheat flour, nine-grain mix, whole wheat flour, rye flour, water, natural starter, vital wheat gluten, salt, yeast",
        price: 6.56,
        sqid: "J4UJQC7LJEE4QFE2FDVJSDND",
        variations: [
          {
            varid: "DYK5D5YUESZS7HNSVTDPBMK4",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p281_i1_w2448.jpeg",
        location: "carlton",
        start: 7,
        lead: 3,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Brioche Loaf",
        description:
          "Wheat flour, water, butter, sugar, milk powder, salt, yeast",
        price: 7.75,
        sqid: "M7ZPR7KYXNN3JLQDD3ZQLZZE",
        variations: [
          {
            varid: "VLVKJUJQH7NM2M7UI3DB2L2Z",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p340_i2_w1000.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Brioche Buns (8)",
        description:
          "Wheat flour, water, butter, sugar, milk powder, salt, yeast",
        price: 7.75,
        sqid: "MGDRYUMVNXHFFOICMQRGFHJO",
        variations: [
          {
            varid: "CPJYNXTEMMF52DTYIJAWS4CB",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p258_i2_w2448.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Brioche Dinner Rolls (12)",
        description:
          "Wheat flour, water, butter, sugar, milk powder, salt, yeast",
        price: 7.75,
        sqid: "JT33KV4Y6E2ALGTJ2BYMCXMN",
        variations: [
          {
            varid: "SDUDPDWHCOBU2GZR5SHGFDNI",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p260_i1_w590.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Olive Herb",
        description:
          "Wheat flour, water, kalamata olives, queen olives, natural starter, herbs de provence, salt, yeast",
        price: 8.25,
        sqid: "OXASXKSSZWM3S4HYIZBOHYQW",
        variations: [
          {
            varid: "SZXTYVLNSU2U5PHCTY5PX7AJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p5_i2_w2448.jpeg",
        location: "prado",
        start: 8.5,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Blue Cheese Walnut",
        description:
          "Wheat flour, water, walnuts, blue cheese, natural starter, salt, yeast",
        price: 8.25,
        sqid: "CVKZ6E3OCHBKDVB7YZPB3J6N",
        variations: [
          {
            varid: "77OSDARFCER2RPZJPMG42SC2",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p3_i2_w2448.jpeg",
        location: "prado",
        start: 8.5,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      
      {
        name: "Epi",
        description: "Wheat flour, water, natural starter, salt, yeast",
        price: 6.0,
        sqid: "QNZ6RBYHJ5JZGYE5VYLI2BMZ",
        variations: [
          {
            varid: "I77ASB3EL5ZJFVN54GDOQOMH",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p11_i2_w600.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "BPB Baguette",
        description: "Wheat flour, water, natural starter, salt, yeast",
        price: 3.7,
        sqid: "MXWDWKDPJ2633BO7SURJWOLM",
        variations: [
          {
            varid: "JGOQX233Y6IR76GRNW32A5BY",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p272_i1_w800.jpeg",
        location: "prado",
        start: 8.5,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Country Rye",
        description:
          "Wheat flour, rye flour, water, natural starter, salt, yeast",
        price: 7.75,
        sqid: "C34CXVX6JXTIACXRDCV5PADU",
        variations: [
          {
            varid: "7M7MZ6A6DTHVOGPJGE4KJJSD",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p279_i1_w2448.jpeg",
        location: "prado",
        start: 8.5,
        lead: 3,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Country Batard",
        description: "Wheat flour, water, natural starter, salt",
        price: 7.75,
        sqid: "M72IUS7PDXZ3QA6DKSNKNRY5",
        variations: [
          {
            varid: "WBS7S2L5TNEZRJ735ZXEONJ3",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p275_i1_w2560.jpeg",
        location: "prado",
        start: 8.5,
        lead: 3,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Multigrain",
        description:
          "Wheat flour, nine-grain mix, whole wheat flour, rye flour, water, natural starter, vital wheat gluten, salt, yeast",
        price: 6.56,
        sqid: "J4UJQC7LJEE4QFE2FDVJSDND",
        variations: [
          {
            varid: "DYK5D5YUESZS7HNSVTDPBMK4",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p281_i1_w2448.jpeg",
        location: "prado",
        start: 8.5,
        lead: 3,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
    ],
  },
];


export const Tmenu = [
  {
    title: "Thanksgiving",
    info: "Order now!!",
    items: [
      {
        name: "Pumpkin Tart (7 in)",
        description:
          "Azita's 7 inch Pumpkin Tart w/ cinnamon and pumpkin ganache.",
        price: 27,
        sqid: "TKFTYXRTLAGK3KAUXPJ5YNSE",
        variations: [
          {
            varid: "L5ZMBJZQPBZMY3CUIF4NHFAM",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
      
        url: "https://items-images-production-f.squarecdn.com/files/01c0f968be980e827611de1e4a7ce75dd1cefe79/original.jpeg?width=516&fit=bounds&enable=upscale",
        location: "carlton",
        start: 7,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },

      
      {
        name: "Chocolate Caramel Pecan Pie (7 in)",
        description: "Azita's Chocolate Caramel Pecan Pie (7 in)",
        price: 27,
        sqid: "7BYMXB5KRH5UMYR6X5ITHCP5",
        variations: [
          {
            varid: "HKJN5XINIWXJDI6TMABQP4YZ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p458_i1_w2791.jpeg",
        location: "carlton",
        start: 7,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      {
        name: "Pumpkin Pie",
        description: "Classic Pumpkin Pie",
        price: 23,
        sqid: "NLCMLDOGFWIZJZQLUOOBVYEI",
        variations: [
          {
            varid: "NVLOBXO4DIXDQV4FBGE36NTD",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p463_i1_w800.jpeg",
        location: "carlton",
        start: 7,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      {
        name: "Pecan Pie",
        description: "Classic Pecan Pie",
        price: 25,
        sqid: "TONUVVEZNERSIUHOZ2T6Y7KG",
        variations: [
          {
            varid: "P7AD2E4C55U7DJ76UL6Z6UZJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p467_i1_w800.jpeg",
        location: "carlton",
        start: 7,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      {
        name: "Brioche Dinner Rolls (12)",
        description:
          "Wheat flour, water, butter, sugar, milk powder, salt, yeast",
        price: 7.35,
        sqid: "JT33KV4Y6E2ALGTJ2BYMCXMN",
        variations: [
          {
            varid: "SDUDPDWHCOBU2GZR5SHGFDNI",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p260_i1_w590.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Pesto Pull Apart Brioche Loaf",
        description: "Pesto Pull Apart Brioche Loaf",
        price: 9.75,
        sqid: "IHXEVJV375MWQDJ6O32KWVLN",
        variations: [
          {
            varid: "MM2KMHYOVSXMM45U3KEZASDJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p469_i1_w2484.jpeg",
        location: "carlton",
        start: 7,
        lead: 2,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      
      {
        name: "Pumpkin Tart (7 in)",
        description:
          "Azita's 7 inch Pumpkin Tart w/ cinnamon and pumpkin ganache.",
        price: 27,
        sqid: "TKFTYXRTLAGK3KAUXPJ5YNSE",
        variations: [
          {
            varid: "L5ZMBJZQPBZMY3CUIF4NHFAM",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://items-images-production-f.squarecdn.com/files/01c0f968be980e827611de1e4a7ce75dd1cefe79/original.jpeg?width=516&fit=bounds&enable=upscale",
        location: "prado",
        start: 8.5,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      

      {
        name: "Chocolate Caramel Pecan Pie (7 in)",
        description: "Azita's Chocolate Caramel Pecan Pie (7 in)",
        price: 27,
        sqid: "7BYMXB5KRH5UMYR6X5ITHCP5",
        variations: [
          {
            varid: "HKJN5XINIWXJDI6TMABQP4YZ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p458_i1_w2791.jpeg",
        location: "prado",
        start: 8.5,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },

      {
        name: "Pumpkin Pie",
        description: "Classic Pumpkin Pie",
        price: 23,
        sqid: "NLCMLDOGFWIZJZQLUOOBVYEI",
        variations: [
          {
            varid: "NVLOBXO4DIXDQV4FBGE36NTD",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p463_i1_w800.jpeg",
        location: "prado",
        start: 8.5,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },

      {
        name: "Pecan Pie",
        description: "Classic Pecan Pie",
        price: 25,
        sqid: "TONUVVEZNERSIUHOZ2T6Y7KG",
        variations: [
          {
            varid: "P7AD2E4C55U7DJ76UL6Z6UZJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p467_i1_w800.jpeg",
        location: "prado",
        start: 8.5,
        lead: 6,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },
      {
        name: "Brioche Dinner Rolls (12)",
        description:
          "Wheat flour, water, butter, sugar, milk powder, salt, yeast",
        price: 7.35,
        sqid: "JT33KV4Y6E2ALGTJ2BYMCXMN",
        variations: [
          {
            varid: "SDUDPDWHCOBU2GZR5SHGFDNI",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        discount: '123456',
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p260_i1_w590.jpeg",
        location: "prado",
        start: 7,
        lead: 2,
        days: null,
        specialStart: null,
        specialEnd: null,
      },
      {
        name: "Pesto Pull Apart Brioche Loaf",
        description: "Pesto Pull Apart Brioche Loaf",
        price: 9.75,
        sqid: "IHXEVJV375MWQDJ6O32KWVLN",
        variations: [
          {
            varid: "MM2KMHYOVSXMM45U3KEZASDJ",
            varDescrip: "",
            priceDiff: 0,
          },
        ],
        url: "https://backporchbakery.square.site/uploads/1/2/9/7/129700795/s925172661976394124_p469_i1_w2484.jpeg",
        location: "prado",
        start: 8.5,
        lead: 2,
        days: null,
        specialStart: "2023-11-21",
        specialEnd: "2023-11-23",
      },

      
    ],
  }]